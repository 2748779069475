'use client'
import React from 'react';
import PropertyCard from '@components/PropertyCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import LinkComponent from '@components/LinkComponent';
import 'swiper/css';
import 'swiper/css/navigation';

const PropertyCardSlider = ({ title, description, properties, cardType, moreUrl = '', dictionary, lang, params }) => {
    return (
    <>
        {properties && properties.length > 0 ? (
            <div className='w-full'>
                <div className='w-full flex flex-col my-4 pl-2 md:pl-0'>
                    <h1 className='text-maincolor text-2xl font-bold'>{title}</h1>
                    <p className='text-maincolor text-xl mb-2'>{description}</p>
                    <div className='sm:block hidden'>
                        <Swiper
                            // slidesPerView={'auto'}
                            spaceBetween={10}
                            slidesPerView={3}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Navigation]}
                            className=''
                        >
                            {properties.map((property, index) => (
                                <SwiperSlide key={index}>
                                    <PropertyCard property={property} cardType={cardType} dictionary={dictionary.card} lang={lang} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    {properties && (
                        <div className='sm:hidden block'>
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={1.5}
                            >
                                {properties.map((property, index) => (
                                    <SwiperSlide key={index}>
                                        <PropertyCard property={property} cardType={cardType} dictionary={dictionary.card} lang={lang} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )}
                </div>
                {moreUrl !== '' && (
                    <div className='w-full flex justify-center mb-3'>
                        <LinkComponent className='w-[300px] bg-maincolor text-xl text-light text-center rounded-md py-2' href={moreUrl}>{dictionary.section.seeMore}</LinkComponent>
                    </div>
                )}
            </div>
        ) : null}
    </>
  )
}

export default PropertyCardSlider